<template>
    <v-dialog v-model="dialog" width=800 persistent>
        <v-card>
            <v-form ref="form" v-model="formValid">

                <v-toolbar color="primary" dark dense>
                    <v-toolbar-title>Datos del personal</v-toolbar-title>
                    <v-spacer/>
                    <v-btn text @click="cancela">
                        <v-icon>mdi-close-box</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text>
                    <v-row no-gutters>
                        <v-col

                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model = "dm.nombre"
                                label="Nombre"
                                rounded
                                filled
                                dense
                            />
                        </v-col>
                        <v-col

                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model = "dm.corto"
                                label="Nombre corto"
                                rounded
                                filled
                                dense
                            />

                        </v-col>
                        <v-col

                            cols="12"
                            md="6"
                        >
                            <v-select
                                v-model = "dm.especialidad"
                                label="Especialidad"
                                :items="especialidades"
                                item-text="especialidad"
                                item-value="id"
                                rounded
                                filled
                                dense
                            />

                        </v-col>
                        <v-col

                            cols="12"
                            md="6"
                        >
                            <v-select
                                v-model = "dm.consultorio"
                                label="Consultorio"
                                :items="consultorios"
                                item-text="consultorio"
                                item-value="id"
                                rounded
                                filled
                                dense
                            />

                        </v-col>
                        <v-col

                            cols="12"
                            md="6"
                        >
                            <v-select
                                v-model = "dm.jornada"
                                label="Turno"
                                :items="turnos"
                                item-text="turno"
                                item-value="id"
                                rounded
                                filled
                                dense
                            />
                        </v-col>
                    </v-row>

                    <editor
                        v-model="dm.observaciones"
                        api-key="5ao0kvhebp3vinnv26st7ce6tgkpakhs1xa30yiwt7w6qrc4"
                        :init="{
                            height: 250,
                            menubar: false,
                            plugins: [
                                'lists link',
                            ],
                            toolbar:
                            'bold italic|bullist numlist|'
                        }"
                    />
                    <div v-for="(itm, id) in 7" :key="id">
                        <div>
                            {{horarios(id)}}
                        </div>
                    </div>
                    <!-- {{datos.horarios}} -->
                </v-card-text>

                <v-card-actions>
                    <v-spacer/>

                    <v-btn text color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                    <v-btn color="success" @click="guarda">
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    props:{
        dialogModal: Boolean,
        datos: Object,
    },
    components:{
        Editor
    },

    data:()=>({
        customToolbar: [["bold", "italic"], [{ list: "ordered" }, { list: "bullet" }]],

        formValid:false,
        dm:{},

        especialidades:[],
        consultorios:[],
        turnos:[]
    }),

    methods:{
        async guarda(){
            this.dialog=false
        },
        cancela(){
            this.dialog=false
        },
        horarios(dia){
            if (this.dm && this.dm.horarios){
                //console.log(dia)
                return(this.dm.horarios.filter(f=>f.day == dia))

            } else {
                return {}
            }
            //return this.dm.horarios.find(itm=>itm.day == dia)
        },
        async cargaDatosIniciales(){
            console.log('cargandoDatosIniciales')
            this.especialidades = await this.quer('/consulta/especialidadesList')
            this.consultorios = await this.quer('/consulta/consultoriosList')
            this.turnos = await this.quer('/consulta/turnoList')
        },
        async quer(ruta){
            try{
                const req = await this.$http({
                    method:"GET",
                    url:ruta,
                })
                return req.data
            }catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogModal},
            set(val){this.$emit('update:dialogModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (!v){
                this.$refs.form.reset()
            }
            if(v){
                this.cargaDatosIniciales()
                this.dm = {...this.datos}
                //
            }
        }
    },
}
</script>

<style>

</style>